import React, { Component } from 'react';
import { array, arrayOf, func, node, number, object, string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import { parseSelectFilterOptions } from '../../../util/search';

import { FieldCheckbox } from '../../../components';

import FilterPlain from '../FilterPlain/FilterPlain';
import FilterPopup from '../FilterPopup/FilterPopup';
import ContentLoader from 'react-content-loader';
import { COLOR, LIKE_NEW } from '../../../util/enums';
import { isArrayLength } from '../../../util/dataExtractors';

import css from './SelectMultipleFilter.module.css';
import { customSortByAlphabeticals } from '../../../util/genericHelpers';
import RenderSizes from './RenderSizes';
import RenderBrand from './RenderBrand';

// Define a mapping for size labels
const sizeLabelMapping = {
  Medium: 'Adult Medium (6-8)',
  'X-Small': 'Adult X-Small (0-2)',
  Large: 'Adult Large (10-12)',
  'X-Large': 'Adult X-Large (14-16)',
  Small: 'Adult Small (4-6)',
};

const Loaders = ({ count = 5 }) => {
  return (
    <>
      {Array.from({ length: count }, (_, index) => (
        <ContentLoader key={index} speed={2} viewBox="0 0 380 70">
          <rect x="0" y="0" rx="5" ry="5" width="40" height="40" />
          <rect x="80" y="10" rx="4" ry="4" width="300" height="20" />
        </ContentLoader>
      ))}
    </>
  );
};

// SelectMultipleFilter doesn't need array mutators since it doesn't require validation.
// TODO: Live edit didn't work with FieldCheckboxGroup
//       There's a mutation problem: formstate.dirty is not reliable with it.
const GroupOfFieldCheckboxes = props => {
  const { id, className, name, selectedOptions } = props;
  let { options } = props;

  const conditionOption =
    isArrayLength(options) &&
    options.map(item => {
      return item?.key === LIKE_NEW ? { key: LIKE_NEW, label: 'Like New' } : item;
    });

  // Transform options based on the mapping
  const sizeOptions =
    options &&
    options.map(item => {
      const label = sizeLabelMapping[item?.key];
      return label ? { key: item.key, label } : item;
    });

  if (name === 'condition') {
    options = conditionOption;
  } else if (name === 'size') {
    options = customSortByAlphabeticals(sizeOptions);
  }

  if (isArrayLength(options) && name !== 'size') {
    options.sort((a, b) => a.label.localeCompare(b.label));
  }

  if (name === 'size') {
    return (
      <RenderSizes
        options={options}
        id={id}
        name={name}
        className={className}
        renderLoader={<Loaders />}
      />
    );
  }

  if (name === 'brand') {
    return (
      <RenderBrand
        options={options}
        id={id}
        name={name}
        className={className}
        renderLoader={<Loaders />}
      />
    );
  }

  return (
    <fieldset className={className}>
      <ul className={css.list}>
        {isArrayLength(options) ? (
          options.map((option, index) => {
            const fieldId = `${id}.${option.key}`;
            return (
              <li
                key={index}
                className={classNames(css.item, {
                  [css.colorItem]: name === COLOR,
                  [css.activeColorItem]:
                    isArrayLength(selectedOptions) && selectedOptions.includes(option?.key),
                })}
              >
                <label htmlFor={fieldId} className={css.imageLabel}>
                  {name === COLOR && option?.icon ? (
                    <img
                      width={20}
                      height={20}
                      src={option?.icon}
                      name={option?.label}
                      alt={option?.label}
                    />
                  ) : null}
                </label>
                <FieldCheckbox id={fieldId} name={name} label={option.label} value={option.key} />
              </li>
            );
          })
        ) : (
          <Loaders />
        )}
      </ul>
    </fieldset>
  );
};

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

// Format URI component's query param: { pub_key: 'has_all:a,b,c' }
const format = (selectedOptions, queryParamName, schemaType, searchMode) => {
  const hasOptionsSelected = selectedOptions && selectedOptions.length > 0;
  const mode = schemaType === 'multi-enum' && searchMode ? `${searchMode}:` : '';
  const value = hasOptionsSelected ? `${mode}${selectedOptions.join(',')}` : null;
  return { [queryParamName]: value };
};

class SelectMultipleFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false,
    };
    this.filter = null;
    this.filterContent = null;

    this.positionStyleForContent = this.positionStyleForContent.bind(this);
  }

  positionStyleForContent() {
    if (this.filter && this.filterContent) {
      // Render the filter content to the right from the menu
      // unless there's no space in which case it is rendered
      // to the left
      const distanceToRight = window.innerWidth - this.filter.getBoundingClientRect().right;
      const labelWidth = this.filter.offsetWidth;
      const contentWidth = this.filterContent.offsetWidth;
      const contentWidthBiggerThanLabel = contentWidth - labelWidth;
      const renderToRight = distanceToRight > contentWidthBiggerThanLabel;
      const contentPlacementOffset = this.props.contentPlacementOffset;

      const offset = renderToRight
        ? { left: contentPlacementOffset }
        : { right: contentPlacementOffset };
      // set a min-width if the content is narrower than the label
      const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

      return { ...offset, ...minWidth };
    }
    return {};
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      name,
      label,
      options,
      initialValues,
      contentPlacementOffset,
      onSubmit,
      queryParamNames,
      schemaType,
      searchMode,
      intl,
      showAsPopup,
      clearDeepFilter,
      ...rest
    } = this.props;

    const optionsWithoutDuplicates = isArrayLength(options)
      ? options?.filter((v, i, a) => a?.findIndex(v2 => v2?.key === v?.key) === i)
      : options;

    const classes = classNames(rootClassName || css.root, className);

    const queryParamName = getQueryParamName(queryParamNames);
    const hasInitialValues = !!initialValues && !!initialValues[queryParamName];
    // Parse options from param strings like "has_all:a,b,c" or "a,b,c"
    const selectedOptions = hasInitialValues
      ? parseSelectFilterOptions(initialValues[queryParamName])
      : [];

    const labelForPopup = hasInitialValues
      ? intl.formatMessage(
          { id: 'SelectMultipleFilter.labelSelected' },
          { labelText: label, count: selectedOptions.length }
        )
      : label;

    const labelSelectionForPlain = hasInitialValues
      ? intl.formatMessage(
          { id: 'SelectMultipleFilterPlainForm.labelSelected' },
          { count: selectedOptions.length }
        )
      : '';

    const contentStyle = this.positionStyleForContent();

    // pass the initial values with the name key so that
    // they can be passed to the correct field
    const namedInitialValues = { [name]: selectedOptions };

    const handleSubmit = values => {
      const usedValue = values ? values[name] : values;
      onSubmit(format(usedValue, queryParamName, schemaType, searchMode));
    };

    return showAsPopup ? (
      <FilterPopup
        className={classes}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        name={name}
        label={labelForPopup}
        isSelected={hasInitialValues}
        id={`${id}.popup`}
        showAsPopup
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        keepDirtyOnReinitialize
        {...rest}
      >
        <GroupOfFieldCheckboxes
          id={`${id}-checkbox-group`}
          className={css.fieldGroup}
          name={name}
          options={optionsWithoutDuplicates}
          selectedOptions={selectedOptions}
        />
      </FilterPopup>
    ) : (
      <FilterPlain
        className={className}
        rootClassName={rootClassName}
        label={label}
        labelSelection={labelSelectionForPlain}
        isSelected={hasInitialValues}
        id={`${id}.plain`}
        liveEdit
        clearDeepFilter={clearDeepFilter}
        contentPlacementOffset={contentStyle}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        {...rest}
      >
        <GroupOfFieldCheckboxes
          id={`${id}-checkbox-group`}
          className={css.fieldGroupPlain}
          name={name}
          options={optionsWithoutDuplicates}
          selectedOptions={selectedOptions}
        />
      </FilterPlain>
    );
  }
}

SelectMultipleFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

SelectMultipleFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSubmit: func.isRequired,
  options: array.isRequired,
  initialValues: object,
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(SelectMultipleFilter);
