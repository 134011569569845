import React, { useState, useRef, useEffect } from 'react';
import { bool, func, node, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import IconPlus from '../IconPlus/IconPlus';
import FilterForm from '../FilterForm/FilterForm';
import css from './FilterPlain.module.css';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { parse } from '../../../util/urlHelpers';
import { useMyContext } from '../../../contexts/StateHolder';

const FilterPlain = ({
  rootClassName,
  className,
  plainClassName,
  id,
  label,
  labelSelection,
  labelSelectionSeparator,
  isSelected,
  children,
  initialValues,
  keepDirtyOnReinitialize,
  contentPlacementOffset,
  clearDeepFilter,
  onSubmit,
  onClear,
  intl,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const filterContentRef = useRef(null);
  const { setClearDeepFilters } = useMyContext();

  const handleChange = values => {
    onSubmit(values);
  };

  const handleClear = () => {
    if (onClear) {
      onClear();
    }
    onSubmit(null);
  };

  const toggleIsOpen = () => {
    setIsOpen(prevState => !prevState);
    if (!isOpen) {
      setClearDeepFilters(false);
    }
  };

  useEffect(() => {
    if (clearDeepFilter) {
      setIsOpen(false);
    }
  }, [clearDeepFilter]);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.filterHeader}>
        <button className={css.labelButton} onClick={toggleIsOpen}>
          <span className={css.labelButtonContent}>
            <span className={css.labelWrapper}>
              <span className={css.label}>
                {label}
                {labelSelection && labelSelectionSeparator ? labelSelectionSeparator : null}
                {labelSelection ? (
                  <span className={css.labelSelected}>{labelSelection}</span>
                ) : null}
              </span>
            </span>
            <span className={css.openSign}>
              <IconPlus isOpen={isOpen} isSelected={isSelected} />
            </span>
          </span>
        </button>
      </div>
      <div
        id={id}
        className={classNames(plainClassName, css.plain, { [css.isOpen]: isOpen })}
        ref={filterContentRef}
      >
        <FilterForm
          id={`${id}.form`}
          liveEdit
          contentPlacementOffset={contentPlacementOffset}
          onChange={handleChange}
          initialValues={initialValues}
          keepDirtyOnReinitialize={keepDirtyOnReinitialize}
        >
          {children}
        </FilterForm>
        <button className={css.clearButton} onClick={handleClear}>
          <FormattedMessage id={'FilterPlain.clear'} />
        </button>
      </div>
    </div>
  );
};

FilterPlain.defaultProps = {
  rootClassName: null,
  className: null,
  plainClassName: null,
  initialValues: null,
  keepDirtyOnReinitialize: false,
  labelSelection: null,
  labelSelectionSeparator: null,
};

FilterPlain.propTypes = {
  rootClassName: string,
  className: string,
  plainClassName: string,
  id: string.isRequired,
  onSubmit: func.isRequired,
  label: node.isRequired,
  labelSelection: node,
  labelSelectionSeparator: node,
  isSelected: bool.isRequired,
  children: node.isRequired,
  initialValues: object,
  keepDirtyOnReinitialize: bool,
  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(FilterPlain);
