import React from 'react';
import classNames from 'classnames';
import { FieldCheckbox } from '../../../components';
import css from './SelectMultipleFilter.module.css';
import { sizeOptions, shoesSizesFilters } from '../../../config/marketplace-custom-config';
import { getChildAndAdultsOptions, transformArrayKeys } from '../../../util/genericHelpers';
import { isArrayLength } from '../../../util/dataExtractors';

// SizeList Component to avoid repetition
const SizeList = ({ id, name, options, title, renderLoader }) => (
  <div>
    <p className={css.optionHeading}>{title}</p>
    <ul className={css.list}>
      {isArrayLength(options)
        ? options.map(option => (
            <li key={`${id}.${option.key}`} className={css.item}>
              <FieldCheckbox
                id={`${id}.${option.key}`}
                name={name}
                label={option.label}
                value={option.key}
              />
            </li>
          ))
        : renderLoader}
    </ul>
  </div>
);

function RenderSizes({ id, name, className, renderLoader }) {
  const sizes = transformArrayKeys(sizeOptions);
  const { childOptions, adultOptions } = getChildAndAdultsOptions(sizes);
  const shoes = transformArrayKeys(shoesSizesFilters);

  return (
    <fieldset className={classNames(className)}>
      <SizeList
        id={id}
        name={name}
        options={adultOptions}
        title="Adult"
        renderLoader={renderLoader}
      />
      <SizeList
        id={id}
        name={name}
        options={childOptions}
        title="Child"
        renderLoader={renderLoader}
      />
      <SizeList id={id} name={name} options={shoes} title="Shoes" renderLoader={renderLoader} />
    </fieldset>
  );
}

export default RenderSizes;
