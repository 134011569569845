import React, { useState } from 'react';
import { bool, func, number, object, string } from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import { Form } from '../../../components';
import { useMyContext } from '../../../contexts/StateHolder';

import css from './PriceFilterForm.module.css';
import { isArrayLength } from '../../../util/dataExtractors';

const DEBOUNCE_WAIT_TIME = 400;

const customPrice = [
  { title: '$0-$20', min: 0, max: 20 },
  { title: '$20-$50', min: 20, max: 50 },
  { title: '$50-$75', min: 50, max: 75 },
  { title: '$75+', min: 75, max: 1000 },
];

// PriceFilterForm component
const PriceFilterFormComponent = props => {
  const { liveEdit, onChange, onSubmit, onCancel, onClear, ...rest } = props;

  if (liveEdit && !onChange) {
    throw new Error('PriceFilterForm: if liveEdit is true you need to provide onChange function');
  }

  if (!liveEdit && !(onCancel && onClear && onSubmit)) {
    throw new Error(
      'PriceFilterForm: if liveEdit is false you need to provide onCancel, onClear, and onSubmit functions'
    );
  }

  const handleChange = debounce(
    formState => {
      if (formState.dirty) {
        const { minPrice, maxPrice, ...restValues } = formState.values;
        onChange({
          minPrice: minPrice === '' ? rest.min : minPrice,
          maxPrice: maxPrice === '' ? rest.max : maxPrice,
          ...restValues,
        });
      }
    },
    DEBOUNCE_WAIT_TIME,
    { leading: false, trailing: true }
  );

  const handleSubmit = values => {
    const { minPrice, maxPrice, ...restValues } = values;
    return onSubmit({
      minPrice: minPrice === '' ? rest.min : minPrice,
      maxPrice: maxPrice === '' ? rest.max : maxPrice,
      ...restValues,
    });
  };

  const formCallbacks = liveEdit
    ? { onSubmit: () => null }
    : { onSubmit: handleSubmit, onCancel, onClear };
  return (
    <FinalForm
      {...rest}
      {...formCallbacks}
      render={formRenderProps => {
        const {
          form,
          handleSubmit,
          showAsPopup,
          onClear,
          onCancel,
          isOpen,
          contentRef,
          style,
          intl,
          values,
          children,
        } = formRenderProps;

        const { clearDeepFilter } = useMyContext();
        const [isActivePill, setIsActivePill] = useState(undefined);

        const handleCancel = () => {
          // reset the final form to initialValues
          form.reset();
          onCancel();
        };

        const clear = intl.formatMessage({ id: 'PriceFilterForm.clear' });
        const cancel = intl.formatMessage({ id: 'PriceFilterForm.cancel' });
        const submit = intl.formatMessage({ id: 'PriceFilterForm.submit' });

        const classes = classNames(css.root, {
          [css.popup]: showAsPopup,
          [css.isOpenAsPopup]: showAsPopup && isOpen,
          [css.plain]: !showAsPopup,
          [css.isOpen]: !showAsPopup && isOpen,
        });

        return (
          <Form
            className={classes}
            onSubmit={handleSubmit}
            tabIndex="0"
            contentRef={contentRef}
            style={{ minWidth: '300px', ...style }}
          >
            <span className={css.sliderWrapper}>
              {isArrayLength(customPrice) &&
                customPrice.map((item, i) => (
                  <span
                    key={i}
                    className={classNames(css.pricePill, {
                      [css.active]: !clearDeepFilter && isActivePill === item.title,
                    })}
                    onClick={() => {
                      if (values) {
                        Object.assign(values, { minPrice: item.min, maxPrice: item.max });
                        onChange(values);
                      }
                      setIsActivePill(item.title);
                    }}
                  >
                    {item.title}
                  </span>
                ))}
            </span>
            {liveEdit ? (
              <FormSpy onChange={handleChange} subscription={{ values: true, dirty: true }} />
            ) : (
              <div className={css.buttonsWrapper}>
                <button className={css.clearButton} type="button" onClick={onClear}>
                  {clear}
                </button>
                <button className={css.cancelButton} type="button" onClick={handleCancel}>
                  {cancel}
                </button>
                <button className={css.submitButton} type="submit">
                  {submit}
                </button>
              </div>
            )}
            {children}
          </Form>
        );
      }}
    />
  );
};

PriceFilterFormComponent.defaultProps = {
  liveEdit: false,
  showAsPopup: false,
  isOpen: false,
  contentRef: null,
  style: null,
  min: 0,
  step: 1,
  onCancel: null,
  onChange: null,
  onClear: null,
  onSubmit: null,
};

PriceFilterFormComponent.propTypes = {
  id: string.isRequired,
  liveEdit: bool,
  showAsPopup: bool,
  onCancel: func,
  onChange: func,
  onClear: func,
  onSubmit: func,
  isOpen: bool,
  contentRef: func,
  style: object,
  min: number.isRequired,
  max: number.isRequired,
  step: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

const PriceFilterForm = injectIntl(PriceFilterFormComponent);

export default PriceFilterForm;
