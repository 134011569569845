import React, { useEffect, useState } from 'react';
import { FieldCheckbox, IconSearch, Icons } from '../../../components';
import { isArrayLength } from '../../../util/dataExtractors';

import css from './SelectMultipleFilter.module.css';
import { useMyContext } from '../../../contexts/StateHolder';

function RenderBrand(props) {
  const { options, id, name, className, renderLoader } = props;

  const [searchInput, setSearchInput] = useState('');
  const [selectedItems, setSelectedItems] = useState({}); // State to track selected items

  const { clearDeepFilter } = useMyContext();

  const handleInputChange = event => {
    setSearchInput(event.target.value);
  };

  useEffect(() => {
    if (clearDeepFilter) {
      setSearchInput('');
      setSelectedItems({});
    }
  }, [clearDeepFilter]);

  const handleCheckboxChange = option => {
    // Toggle the selection state of the item
    const isSelected = !!selectedItems[option.key];
    setSelectedItems(prev => ({
      ...prev,
      [option.key]: !isSelected,
    }));
  };

  // Always include all selected items in the pinnedOptions list
  let pinnedOptions = options.filter(option => selectedItems[option.key]);

  // Filter regularOptions based on search input
  const regularOptions = options.filter(
    option =>
      !selectedItems[option.key] &&
      (searchInput.trim() === '' || option.label.toLowerCase().includes(searchInput.toLowerCase()))
  );

  return (
    <fieldset className={className}>
      <div className={css.searchBlock}>
        <IconSearch />
        <input
          type="text"
          placeholder="Search..."
          value={searchInput}
          onChange={handleInputChange}
          className={css.searchInput}
        />
      </div>
      {isArrayLength(pinnedOptions) && (
        <div className={css.pinnedListSec}>
          <div className={css.pinnedHeader}>
            Selected Brands{' '}
            {/* <span
              className={css.clearBtn}
              onClick={() => {
                setSelectedItems({});
              }}
            >
              Clear
            </span> */}
          </div>
          <ul className={css.pinnedList}>
            {pinnedOptions.map((option, index) => (
              <li key={`pinned-${index}`} className={css.item}>
                <FieldCheckbox
                  id={`${id}.${option.key}`}
                  name={name}
                  label={option.label}
                  value={option.key}
                  checked={true}
                  onChange={() => handleCheckboxChange(option)}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
      <ul className={css.regularList}>
        {isArrayLength(regularOptions)
          ? regularOptions.map((option, index) => (
              <li key={index} className={css.item}>
                <FieldCheckbox
                  id={`${id}.${option.key}`}
                  name={name}
                  label={option.label}
                  value={option.key}
                  checked={false}
                  onChange={() => handleCheckboxChange(option)}
                />
              </li>
            ))
          : renderLoader}
      </ul>
    </fieldset>
  );
}

export default RenderBrand;
